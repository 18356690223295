// import React from "react";
// import Breadcrumb from './Breadcrumb';
// import Sidenavigation from "../../layouts/Sidenavigation";
// import Topnavigation from "../../layouts/Topnavigation";
// import Quickbar from "../../layouts/Quickbar";

// function AddUsers() {
//     return (
//         <>
//             <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
//                 <Sidenavigation />
//                 <main className="body-content">
//                     <Topnavigation />
//                     <div className="ms-content-wrapper">
//                         <div className="row">
//                             <div className="col-md-12" >
//                                 <Breadcrumb />
//                                 <div className="alert alert-success" role="alert" style={{ display: 'none' }}>
//                                     <strong>Well done!</strong> You successfully read this important alert message.
//                                 </div>
//                             </div>
//                             <div className="col-xl-12 col-md-12">
//                                 <div className="ms-panel ms-panel-fh">
//                                     <div className="ms-panel-header">
//                                         <h6>Add Users Form</h6>
//                                     </div>
//                                     <div className="ms-panel-body">
//                                         <form className="needs-validation clearfix" key="addform">
//                                             <div className="form-row">
//                                                 <div className="col-md-12 mb-3">
//                                                     <label htmlFor="Name">Name</label>
//                                                     <div className="input-group">
//                                                         <input type="text" className="form-control" id="Name" placeholder="Enter Name" required name="Name" />
//                                                     </div>
//                                                     <label htmlFor="Phone">Phone</label>
//                                                     <div className="input-group">
//                                                         <input type="text" className="form-control" id="Phone" placeholder="Enter Phone Number" required name="Phone" />
//                                                     </div>
//                                                     <label htmlFor="Email">Email</label>
//                                                     <div className="input-group">
//                                                         <input type="text" className="form-control" id="Email" placeholder="Enter Email" required name="Email" />
//                                                     </div>
//                                                 </div>
//                                                 <div className="ms-panel-header new">
//                                                     <button className="btn btn-primary d-block" type="button">Save</button>
//                                                 </div>
//                                             </div>
//                                         </form>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </main>
//                 <Quickbar />
//             </div>
//         </>
//     );
// }

// export default AddUsers;



import React, { useState } from "react";
import Breadcrumb from './Breadcrumb';
import Sidenavigation from "../../layouts/Sidenavigation";
import Topnavigation from "../../layouts/Topnavigation";
import Quickbar from "../../layouts/Quickbar";
import axios from 'axios';

function AddUsers() {
    const [formData, setFormData] = useState({
        user_name: '',
        email: '',
        phone_number: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formDataObj = new FormData();
            formDataObj.append('user_name', formData.user_name);
            formDataObj.append('email', formData.email);
            formDataObj.append('phone_number', formData.phone_number);

            await axios.post('https://apimenu3.haikutheasiankitchen.com/index.php/Api/registration', formDataObj, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            window.location.href = '/report';
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    
    return (
        <>
            <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
                <Sidenavigation />
                <main className="body-content">
                    <Topnavigation />
                    <div className="ms-content-wrapper">
                        <div className="row">
                            <div className="col-md-12" >
                                <Breadcrumb />
                                <div className="alert alert-success" role="alert" style={{ display: 'none' }}>
                                    <strong>Well done!</strong> You successfully read this important alert message.
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header">
                                        <h6>Add Users Form</h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        <form className="needs-validation clearfix" key="addform" onSubmit={handleSubmit}>
                                            <div className="form-row">
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="user_name">Name</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="user_name" placeholder="Enter Name" required name="user_name" value={formData.user_name} onChange={handleInputChange} />
                                                    </div>
                                                    <label htmlFor="phone_number">Phone</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="phone_number" placeholder="Enter Phone Number" required name="phone_number" value={formData.phone_number} onChange={handleInputChange} />
                                                    </div>
                                                    <label htmlFor="email">Email</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="email" placeholder="Enter Email" required name="email" value={formData.email} onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="ms-panel-header new">
                                                    <button className="btn btn-primary d-block" type="submit">Save</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Quickbar />
            </div>
        </>
    );
}

export default AddUsers;
